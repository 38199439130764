var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/* ========================================================================
 * bootstrap-switch - v3.3.2
 * http://www.bootstrap-switch.org
 * ========================================================================
 * Copyright 2012-2013 Mattia Larentis
 *
 * ========================================================================
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ========================================================================
 */
(function () {
  var __slice = [].slice;

  (function ($, window) {
    "use strict";

    var BootstrapSwitch;

    BootstrapSwitch = function () {
      function BootstrapSwitch(element, options) {
        if (options == null) {
          options = {};
        }

        (this || _global).$element = $(element);
        (this || _global).options = $.extend({}, $.fn.bootstrapSwitch.defaults, {
          state: (this || _global).$element.is(":checked"),
          size: (this || _global).$element.data("size"),
          animate: (this || _global).$element.data("animate"),
          disabled: (this || _global).$element.is(":disabled"),
          readonly: (this || _global).$element.is("[readonly]"),
          indeterminate: (this || _global).$element.data("indeterminate"),
          inverse: (this || _global).$element.data("inverse"),
          radioAllOff: (this || _global).$element.data("radio-all-off"),
          onColor: (this || _global).$element.data("on-color"),
          offColor: (this || _global).$element.data("off-color"),
          onText: (this || _global).$element.data("on-text"),
          offText: (this || _global).$element.data("off-text"),
          labelText: (this || _global).$element.data("label-text"),
          handleWidth: (this || _global).$element.data("handle-width"),
          labelWidth: (this || _global).$element.data("label-width"),
          baseClass: (this || _global).$element.data("base-class"),
          wrapperClass: (this || _global).$element.data("wrapper-class")
        }, options);
        (this || _global).$wrapper = $("<div>", {
          "class": function (_this) {
            return function () {
              var classes;
              classes = ["" + _this.options.baseClass].concat(_this._getClasses(_this.options.wrapperClass));
              classes.push(_this.options.state ? "" + _this.options.baseClass + "-on" : "" + _this.options.baseClass + "-off");

              if (_this.options.size != null) {
                classes.push("" + _this.options.baseClass + "-" + _this.options.size);
              }

              if (_this.options.disabled) {
                classes.push("" + _this.options.baseClass + "-disabled");
              }

              if (_this.options.readonly) {
                classes.push("" + _this.options.baseClass + "-readonly");
              }

              if (_this.options.indeterminate) {
                classes.push("" + _this.options.baseClass + "-indeterminate");
              }

              if (_this.options.inverse) {
                classes.push("" + _this.options.baseClass + "-inverse");
              }

              if (_this.$element.attr("id")) {
                classes.push("" + _this.options.baseClass + "-id-" + _this.$element.attr("id"));
              }

              return classes.join(" ");
            };
          }(this || _global)()
        });
        (this || _global).$container = $("<div>", {
          "class": "" + (this || _global).options.baseClass + "-container"
        });
        (this || _global).$on = $("<span>", {
          html: (this || _global).options.onText,
          "class": "" + (this || _global).options.baseClass + "-handle-on " + (this || _global).options.baseClass + "-" + (this || _global).options.onColor
        });
        (this || _global).$off = $("<span>", {
          html: (this || _global).options.offText,
          "class": "" + (this || _global).options.baseClass + "-handle-off " + (this || _global).options.baseClass + "-" + (this || _global).options.offColor
        });
        (this || _global).$label = $("<span>", {
          html: (this || _global).options.labelText,
          "class": "" + (this || _global).options.baseClass + "-label"
        });

        (this || _global).$element.on("init.bootstrapSwitch", function (_this) {
          return function () {
            return _this.options.onInit.apply(element, arguments);
          };
        }(this || _global));

        (this || _global).$element.on("switchChange.bootstrapSwitch", function (_this) {
          return function () {
            return _this.options.onSwitchChange.apply(element, arguments);
          };
        }(this || _global));

        (this || _global).$container = (this || _global).$element.wrap((this || _global).$container).parent();
        (this || _global).$wrapper = (this || _global).$container.wrap((this || _global).$wrapper).parent();

        (this || _global).$element.before((this || _global).options.inverse ? (this || _global).$off : (this || _global).$on).before((this || _global).$label).before((this || _global).options.inverse ? (this || _global).$on : (this || _global).$off);

        if ((this || _global).options.indeterminate) {
          (this || _global).$element.prop("indeterminate", true);
        }

        this._init();

        this._elementHandlers();

        this._handleHandlers();

        this._labelHandlers();

        this._formHandler();

        this._externalLabelHandler();

        (this || _global).$element.trigger("init.bootstrapSwitch");
      }

      BootstrapSwitch.prototype._constructor = BootstrapSwitch;

      BootstrapSwitch.prototype.state = function (value, skip) {
        if (typeof value === "undefined") {
          return (this || _global).options.state;
        }

        if ((this || _global).options.disabled || (this || _global).options.readonly) {
          return (this || _global).$element;
        }

        if ((this || _global).options.state && !(this || _global).options.radioAllOff && (this || _global).$element.is(":radio")) {
          return (this || _global).$element;
        }

        if ((this || _global).options.indeterminate) {
          this.indeterminate(false);
        }

        value = !!value;

        (this || _global).$element.prop("checked", value).trigger("change.bootstrapSwitch", skip);

        return (this || _global).$element;
      };

      BootstrapSwitch.prototype.toggleState = function (skip) {
        if ((this || _global).options.disabled || (this || _global).options.readonly) {
          return (this || _global).$element;
        }

        if ((this || _global).options.indeterminate) {
          this.indeterminate(false);
          return this.state(true);
        } else {
          return (this || _global).$element.prop("checked", !(this || _global).options.state).trigger("change.bootstrapSwitch", skip);
        }
      };

      BootstrapSwitch.prototype.size = function (value) {
        if (typeof value === "undefined") {
          return (this || _global).options.size;
        }

        if ((this || _global).options.size != null) {
          (this || _global).$wrapper.removeClass("" + (this || _global).options.baseClass + "-" + (this || _global).options.size);
        }

        if (value) {
          (this || _global).$wrapper.addClass("" + (this || _global).options.baseClass + "-" + value);
        }

        this._width();

        this._containerPosition();

        (this || _global).options.size = value;
        return (this || _global).$element;
      };

      BootstrapSwitch.prototype.animate = function (value) {
        if (typeof value === "undefined") {
          return (this || _global).options.animate;
        }

        value = !!value;

        if (value === (this || _global).options.animate) {
          return (this || _global).$element;
        }

        return this.toggleAnimate();
      };

      BootstrapSwitch.prototype.toggleAnimate = function () {
        (this || _global).options.animate = !(this || _global).options.animate;

        (this || _global).$wrapper.toggleClass("" + (this || _global).options.baseClass + "-animate");

        return (this || _global).$element;
      };

      BootstrapSwitch.prototype.disabled = function (value) {
        if (typeof value === "undefined") {
          return (this || _global).options.disabled;
        }

        value = !!value;

        if (value === (this || _global).options.disabled) {
          return (this || _global).$element;
        }

        return this.toggleDisabled();
      };

      BootstrapSwitch.prototype.toggleDisabled = function () {
        (this || _global).options.disabled = !(this || _global).options.disabled;

        (this || _global).$element.prop("disabled", (this || _global).options.disabled);

        (this || _global).$wrapper.toggleClass("" + (this || _global).options.baseClass + "-disabled");

        return (this || _global).$element;
      };

      BootstrapSwitch.prototype.readonly = function (value) {
        if (typeof value === "undefined") {
          return (this || _global).options.readonly;
        }

        value = !!value;

        if (value === (this || _global).options.readonly) {
          return (this || _global).$element;
        }

        return this.toggleReadonly();
      };

      BootstrapSwitch.prototype.toggleReadonly = function () {
        (this || _global).options.readonly = !(this || _global).options.readonly;

        (this || _global).$element.prop("readonly", (this || _global).options.readonly);

        (this || _global).$wrapper.toggleClass("" + (this || _global).options.baseClass + "-readonly");

        return (this || _global).$element;
      };

      BootstrapSwitch.prototype.indeterminate = function (value) {
        if (typeof value === "undefined") {
          return (this || _global).options.indeterminate;
        }

        value = !!value;

        if (value === (this || _global).options.indeterminate) {
          return (this || _global).$element;
        }

        return this.toggleIndeterminate();
      };

      BootstrapSwitch.prototype.toggleIndeterminate = function () {
        (this || _global).options.indeterminate = !(this || _global).options.indeterminate;

        (this || _global).$element.prop("indeterminate", (this || _global).options.indeterminate);

        (this || _global).$wrapper.toggleClass("" + (this || _global).options.baseClass + "-indeterminate");

        this._containerPosition();

        return (this || _global).$element;
      };

      BootstrapSwitch.prototype.inverse = function (value) {
        if (typeof value === "undefined") {
          return (this || _global).options.inverse;
        }

        value = !!value;

        if (value === (this || _global).options.inverse) {
          return (this || _global).$element;
        }

        return this.toggleInverse();
      };

      BootstrapSwitch.prototype.toggleInverse = function () {
        var $off, $on;

        (this || _global).$wrapper.toggleClass("" + (this || _global).options.baseClass + "-inverse");

        $on = (this || _global).$on.clone(true);
        $off = (this || _global).$off.clone(true);

        (this || _global).$on.replaceWith($off);

        (this || _global).$off.replaceWith($on);

        (this || _global).$on = $off;
        (this || _global).$off = $on;
        (this || _global).options.inverse = !(this || _global).options.inverse;
        return (this || _global).$element;
      };

      BootstrapSwitch.prototype.onColor = function (value) {
        var color;
        color = (this || _global).options.onColor;

        if (typeof value === "undefined") {
          return color;
        }

        if (color != null) {
          (this || _global).$on.removeClass("" + (this || _global).options.baseClass + "-" + color);
        }

        (this || _global).$on.addClass("" + (this || _global).options.baseClass + "-" + value);

        (this || _global).options.onColor = value;
        return (this || _global).$element;
      };

      BootstrapSwitch.prototype.offColor = function (value) {
        var color;
        color = (this || _global).options.offColor;

        if (typeof value === "undefined") {
          return color;
        }

        if (color != null) {
          (this || _global).$off.removeClass("" + (this || _global).options.baseClass + "-" + color);
        }

        (this || _global).$off.addClass("" + (this || _global).options.baseClass + "-" + value);

        (this || _global).options.offColor = value;
        return (this || _global).$element;
      };

      BootstrapSwitch.prototype.onText = function (value) {
        if (typeof value === "undefined") {
          return (this || _global).options.onText;
        }

        (this || _global).$on.html(value);

        this._width();

        this._containerPosition();

        (this || _global).options.onText = value;
        return (this || _global).$element;
      };

      BootstrapSwitch.prototype.offText = function (value) {
        if (typeof value === "undefined") {
          return (this || _global).options.offText;
        }

        (this || _global).$off.html(value);

        this._width();

        this._containerPosition();

        (this || _global).options.offText = value;
        return (this || _global).$element;
      };

      BootstrapSwitch.prototype.labelText = function (value) {
        if (typeof value === "undefined") {
          return (this || _global).options.labelText;
        }

        (this || _global).$label.html(value);

        this._width();

        (this || _global).options.labelText = value;
        return (this || _global).$element;
      };

      BootstrapSwitch.prototype.handleWidth = function (value) {
        if (typeof value === "undefined") {
          return (this || _global).options.handleWidth;
        }

        (this || _global).options.handleWidth = value;

        this._width();

        this._containerPosition();

        return (this || _global).$element;
      };

      BootstrapSwitch.prototype.labelWidth = function (value) {
        if (typeof value === "undefined") {
          return (this || _global).options.labelWidth;
        }

        (this || _global).options.labelWidth = value;

        this._width();

        this._containerPosition();

        return (this || _global).$element;
      };

      BootstrapSwitch.prototype.baseClass = function (value) {
        return (this || _global).options.baseClass;
      };

      BootstrapSwitch.prototype.wrapperClass = function (value) {
        if (typeof value === "undefined") {
          return (this || _global).options.wrapperClass;
        }

        if (!value) {
          value = $.fn.bootstrapSwitch.defaults.wrapperClass;
        }

        (this || _global).$wrapper.removeClass(this._getClasses((this || _global).options.wrapperClass).join(" "));

        (this || _global).$wrapper.addClass(this._getClasses(value).join(" "));

        (this || _global).options.wrapperClass = value;
        return (this || _global).$element;
      };

      BootstrapSwitch.prototype.radioAllOff = function (value) {
        if (typeof value === "undefined") {
          return (this || _global).options.radioAllOff;
        }

        value = !!value;

        if (value === (this || _global).options.radioAllOff) {
          return (this || _global).$element;
        }

        (this || _global).options.radioAllOff = value;
        return (this || _global).$element;
      };

      BootstrapSwitch.prototype.onInit = function (value) {
        if (typeof value === "undefined") {
          return (this || _global).options.onInit;
        }

        if (!value) {
          value = $.fn.bootstrapSwitch.defaults.onInit;
        }

        (this || _global).options.onInit = value;
        return (this || _global).$element;
      };

      BootstrapSwitch.prototype.onSwitchChange = function (value) {
        if (typeof value === "undefined") {
          return (this || _global).options.onSwitchChange;
        }

        if (!value) {
          value = $.fn.bootstrapSwitch.defaults.onSwitchChange;
        }

        (this || _global).options.onSwitchChange = value;
        return (this || _global).$element;
      };

      BootstrapSwitch.prototype.destroy = function () {
        var $form;
        $form = (this || _global).$element.closest("form");

        if ($form.length) {
          $form.off("reset.bootstrapSwitch").removeData("bootstrap-switch");
        }

        (this || _global).$container.children().not((this || _global).$element).remove();

        (this || _global).$element.unwrap().unwrap().off(".bootstrapSwitch").removeData("bootstrap-switch");

        return (this || _global).$element;
      };

      BootstrapSwitch.prototype._width = function () {
        var $handles, handleWidth;
        $handles = (this || _global).$on.add((this || _global).$off);
        $handles.add((this || _global).$label).css("width", "");
        handleWidth = (this || _global).options.handleWidth === "auto" ? Math.max((this || _global).$on.width(), (this || _global).$off.width()) : (this || _global).options.handleWidth;
        $handles.width(handleWidth);

        (this || _global).$label.width(function (_this) {
          return function (index, width) {
            if (_this.options.labelWidth !== "auto") {
              return _this.options.labelWidth;
            }

            if (width < handleWidth) {
              return handleWidth;
            } else {
              return width;
            }
          };
        }(this || _global));

        (this || _global)._handleWidth = (this || _global).$on.outerWidth();
        (this || _global)._labelWidth = (this || _global).$label.outerWidth();

        (this || _global).$container.width((this || _global)._handleWidth * 2 + (this || _global)._labelWidth);

        return (this || _global).$wrapper.width((this || _global)._handleWidth + (this || _global)._labelWidth);
      };

      BootstrapSwitch.prototype._containerPosition = function (state, callback) {
        if (state == null) {
          state = (this || _global).options.state;
        }

        (this || _global).$container.css("margin-left", function (_this) {
          return function () {
            var values;
            values = [0, "-" + _this._handleWidth + "px"];

            if (_this.options.indeterminate) {
              return "-" + _this._handleWidth / 2 + "px";
            }

            if (state) {
              if (_this.options.inverse) {
                return values[1];
              } else {
                return values[0];
              }
            } else {
              if (_this.options.inverse) {
                return values[0];
              } else {
                return values[1];
              }
            }
          };
        }(this || _global));

        if (!callback) {
          return;
        }

        return setTimeout(function () {
          return callback();
        }, 50);
      };

      BootstrapSwitch.prototype._init = function () {
        var init, initInterval;

        init = function (_this) {
          return function () {
            _this._width();

            return _this._containerPosition(null, function () {
              if (_this.options.animate) {
                return _this.$wrapper.addClass("" + _this.options.baseClass + "-animate");
              }
            });
          };
        }(this || _global);

        if ((this || _global).$wrapper.is(":visible")) {
          return init();
        }

        return initInterval = window.setInterval(function (_this) {
          return function () {
            if (_this.$wrapper.is(":visible")) {
              init();
              return window.clearInterval(initInterval);
            }
          };
        }(this || _global), 50);
      };

      BootstrapSwitch.prototype._elementHandlers = function () {
        return (this || _global).$element.on({
          "change.bootstrapSwitch": function (_this) {
            return function (e, skip) {
              var state;
              e.preventDefault();
              e.stopImmediatePropagation();
              state = _this.$element.is(":checked");

              _this._containerPosition(state);

              if (state === _this.options.state) {
                return;
              }

              _this.options.state = state;

              _this.$wrapper.toggleClass("" + _this.options.baseClass + "-off").toggleClass("" + _this.options.baseClass + "-on");

              if (!skip) {
                if (_this.$element.is(":radio")) {
                  $("[name='" + _this.$element.attr("name") + "']").not(_this.$element).prop("checked", false).trigger("change.bootstrapSwitch", true);
                }

                return _this.$element.trigger("switchChange.bootstrapSwitch", [state]);
              }
            };
          }(this || _global),
          "focus.bootstrapSwitch": function (_this) {
            return function (e) {
              e.preventDefault();
              return _this.$wrapper.addClass("" + _this.options.baseClass + "-focused");
            };
          }(this || _global),
          "blur.bootstrapSwitch": function (_this) {
            return function (e) {
              e.preventDefault();
              return _this.$wrapper.removeClass("" + _this.options.baseClass + "-focused");
            };
          }(this || _global),
          "keydown.bootstrapSwitch": function (_this) {
            return function (e) {
              if (!e.which || _this.options.disabled || _this.options.readonly) {
                return;
              }

              switch (e.which) {
                case 37:
                  e.preventDefault();
                  e.stopImmediatePropagation();
                  return _this.state(false);

                case 39:
                  e.preventDefault();
                  e.stopImmediatePropagation();
                  return _this.state(true);
              }
            };
          }(this || _global)
        });
      };

      BootstrapSwitch.prototype._handleHandlers = function () {
        (this || _global).$on.on("click.bootstrapSwitch", function (_this) {
          return function (event) {
            event.preventDefault();
            event.stopPropagation();

            _this.state(false);

            return _this.$element.trigger("focus.bootstrapSwitch");
          };
        }(this || _global));

        return (this || _global).$off.on("click.bootstrapSwitch", function (_this) {
          return function (event) {
            event.preventDefault();
            event.stopPropagation();

            _this.state(true);

            return _this.$element.trigger("focus.bootstrapSwitch");
          };
        }(this || _global));
      };

      BootstrapSwitch.prototype._labelHandlers = function () {
        return (this || _global).$label.on({
          "mousedown.bootstrapSwitch touchstart.bootstrapSwitch": function (_this) {
            return function (e) {
              if (_this._dragStart || _this.options.disabled || _this.options.readonly) {
                return;
              }

              e.preventDefault();
              e.stopPropagation();
              _this._dragStart = (e.pageX || e.originalEvent.touches[0].pageX) - parseInt(_this.$container.css("margin-left"), 10);

              if (_this.options.animate) {
                _this.$wrapper.removeClass("" + _this.options.baseClass + "-animate");
              }

              return _this.$element.trigger("focus.bootstrapSwitch");
            };
          }(this || _global),
          "mousemove.bootstrapSwitch touchmove.bootstrapSwitch": function (_this) {
            return function (e) {
              var difference;

              if (_this._dragStart == null) {
                return;
              }

              e.preventDefault();
              difference = (e.pageX || e.originalEvent.touches[0].pageX) - _this._dragStart;

              if (difference < -_this._handleWidth || difference > 0) {
                return;
              }

              _this._dragEnd = difference;
              return _this.$container.css("margin-left", "" + _this._dragEnd + "px");
            };
          }(this || _global),
          "mouseup.bootstrapSwitch touchend.bootstrapSwitch": function (_this) {
            return function (e) {
              var state;

              if (!_this._dragStart) {
                return;
              }

              e.preventDefault();

              if (_this.options.animate) {
                _this.$wrapper.addClass("" + _this.options.baseClass + "-animate");
              }

              if (_this._dragEnd) {
                state = _this._dragEnd > -(_this._handleWidth / 2);
                _this._dragEnd = false;

                _this.state(_this.options.inverse ? !state : state);
              } else {
                _this.state(!_this.options.state);
              }

              return _this._dragStart = false;
            };
          }(this || _global),
          "mouseleave.bootstrapSwitch": function (_this) {
            return function (e) {
              return _this.$label.trigger("mouseup.bootstrapSwitch");
            };
          }(this || _global)
        });
      };

      BootstrapSwitch.prototype._externalLabelHandler = function () {
        var $externalLabel;
        $externalLabel = (this || _global).$element.closest("label");
        return $externalLabel.on("click", function (_this) {
          return function (event) {
            event.preventDefault();
            event.stopImmediatePropagation();

            if (event.target === $externalLabel[0]) {
              return _this.toggleState();
            }
          };
        }(this || _global));
      };

      BootstrapSwitch.prototype._formHandler = function () {
        var $form;
        $form = (this || _global).$element.closest("form");

        if ($form.data("bootstrap-switch")) {
          return;
        }

        return $form.on("reset.bootstrapSwitch", function () {
          return window.setTimeout(function () {
            return $form.find("input").filter(function () {
              return $(this || _global).data("bootstrap-switch");
            }).each(function () {
              return $(this || _global).bootstrapSwitch("state", (this || _global).checked);
            });
          }, 1);
        }).data("bootstrap-switch", true);
      };

      BootstrapSwitch.prototype._getClasses = function (classes) {
        var c, cls, _i, _len;

        if (!$.isArray(classes)) {
          return ["" + (this || _global).options.baseClass + "-" + classes];
        }

        cls = [];

        for (_i = 0, _len = classes.length; _i < _len; _i++) {
          c = classes[_i];
          cls.push("" + (this || _global).options.baseClass + "-" + c);
        }

        return cls;
      };

      return BootstrapSwitch;
    }();

    $.fn.bootstrapSwitch = function () {
      var args, option, ret;
      option = arguments[0], args = 2 <= arguments.length ? __slice.call(arguments, 1) : [];
      ret = this || _global;
      this.each(function () {
        var $this, data;
        $this = $(this || _global);
        data = $this.data("bootstrap-switch");

        if (!data) {
          $this.data("bootstrap-switch", data = new BootstrapSwitch(this || _global, option));
        }

        if (typeof option === "string") {
          return ret = data[option].apply(data, args);
        }
      });
      return ret;
    };

    $.fn.bootstrapSwitch.Constructor = BootstrapSwitch;
    return $.fn.bootstrapSwitch.defaults = {
      state: true,
      size: null,
      animate: true,
      disabled: false,
      readonly: false,
      indeterminate: false,
      inverse: false,
      radioAllOff: false,
      onColor: "primary",
      offColor: "default",
      onText: "ON",
      offText: "OFF",
      labelText: "&nbsp;",
      handleWidth: "auto",
      labelWidth: "auto",
      baseClass: "bootstrap-switch",
      wrapperClass: "wrapper",
      onInit: function () {},
      onSwitchChange: function () {}
    };
  })(window.jQuery, window);
}).call(exports);
export default exports;